.filter-shelves {
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  margin-top: 12px;
  margin-left: 10px;
  border: 1px solid #eee;
  background-color: #f2f2f2;
  -webkit-box-shadow: 1px 3px 4px 0px rgba(17, 17, 17, 0.52);
  box-shadow: 1px 3px 4px 0px rgba(17, 17, 17, 0.52);
  display: flex;
  .flex-grow1 {
    flex-grow: 1;
  }
  .actions-shelf {
    color: #609bc7;
  }

  .f-shelves-list-container {
    background-color: #fff;
    overflow: hidden;
    height: 422px;
  }

  .f-shelves-save-filter {
    border: 0;
    outline: 0;
    width: 130px;
    text-align: center;
    background-color: #f2f2f2;
  }

  .f-saved-shelves-list {
    width: 250px;
    list-style: none;
    li {
      font-size: 1.1em;
      cursor: pointer;
      margin: 4px 0;
      background-color: #e6e6e6;
      border-radius: 3px;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
    }
    .f-custom-active {
      background-color: #99ccff;
    }
    .f-shelves-active {
      background-color: #afdde9;
    }
  }

  .f-shelves-scrollbar {
    height: 120px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .f-shelves-list {
    width: 250px;
    list-style: none;
    li {
      font-size: 1.1em;
      cursor: pointer;
      padding: 5px 10px;
      margin: 4px 0;
      background-color: #e6e6e6;
      border-radius: 3px;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      display: flex;
      justify-content: space-between;
    }
    .f-options-active {
      background-color: #afc;
    }
    .f-options-active {
      background-color: #afc;
    }
    .f-items-active {
      background-color: #f6ffd5;
    }
    .f-items-close {
      flex-grow: 30px;
      margin-left: 5px;
    }
  }
}
