.vendor-list-item-container {
  border-radius: 0.5vh;
  border: 2.5px solid lightgrey;
  flex-wrap: wrap;
  height: 90px;
  cursor: pointer;
  &:hover {
    border: 2.5px solid $color_dark_blue;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
    box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
  }
}
.button-import {
  margin-left: 2vh;
  color: white;
  background-color: #550000;
}

.button-search {
  color: white;
  background-color: #3a78b3;
  margin-top: 4vh;
  width: 300px;
}
